const $ = require("jquery");
window.$ = $;
window.jQuery = jQuery;
const tmpl = require('blueimp-tmpl');

const SEARCH_TYPE = {
  GLOBAL: 'global',
  INLIST: 'inlist'
};

import templateMain from './templates/template-main';
import templateItem from './templates/template-item';

const CSS_CLASESS = {
  SEARCH_ITEMS: 'wdt-form-playlist-search-items',
  SELECT_USER_ITEMS: 'wdt-form-playlist-inuser-items',
  SELECT_ITEMS: 'wdt-form-playlist-items',
  SEARCH_INPUT: 'wdt-form-playlist-search-input'
};

class FormPlayListWidget {
  
  constructor(element, options) {
    
    let defaultOpts = {
	  endPointUrl: null,
	  searchDelay:  300
	};
 
	this.element = $(element);
	this.options = $.extend({} , defaultOpts, options);
	this.playlistId = null;
	
	this.items = [];
	
	
	this.searchType = null;
	this.init();
	this.render();
	this.loadPlayList();
  }
  
  
  init() {
	this.element.find('.wgt-wrap-content').empty();
	this.playlistId = this.element.attr('data-playlist-id');
  }
  
  
  render() {
	const compileTmpl = tmpl(templateMain(), {
	  class_search_items: CSS_CLASESS.SEARCH_ITEMS,
	  class_select_user_items: CSS_CLASESS.SELECT_USER_ITEMS,
	  class_select_items: CSS_CLASESS.SELECT_ITEMS,
	  class_search_input: CSS_CLASESS.SEARCH_INPUT
	});
	this.element.find('.wgt-wrap-content').append(compileTmpl);
	this.initEvents();
  }
  
  getSearchValue(){
    return this.element.find('.' + CSS_CLASESS.SEARCH_INPUT).val();
  }
  
  setSearchValue(val){
	return this.element.find('.' + CSS_CLASESS.SEARCH_INPUT).val(val);
  }
  
  getSelectorSearchItems(){
    return this.element.find('.' + CSS_CLASESS.SEARCH_ITEMS);
  }
  
  getSelectorUserItems(){
    return this.element.find('.' + CSS_CLASESS.SELECT_USER_ITEMS);
  }
  
  getSelectorSelectItems(){
	return this.element.find('.' + CSS_CLASESS.SELECT_ITEMS);
  }
  
  
  loadPlayList(){
    
    if(Number(this.playlistId) === 0){
      return false;
	}
 
	if (this.hdx) {
	  this.hdx.abort();
	  this.hdx = null;
	}
 
	let wrap = this.getSelectorSelectItems();
	this.hdx = $.ajax({
	  url: this.options.endPointUrl,
	  data: {q: null, type: 'load-list', id: this.playlistId},
	  success: (data) => {
		this.items = [];
	    data.map((obj) => {
	      this.addItemIndex(obj.id)
		});
		this.renderItems(wrap, data, true, ['bnt-remove-audio']);
		this.savePlayListToInput();
	  }
	});
	
  }
  
  fetchRequest() {
	if (this.hdx) {
	  this.hdx.abort();
	  this.hdx = null;
	  
	}
	let qText = this.getSearchValue();
	
	this.hdx = $.ajax({
	  url: this.options.endPointUrl,
	  data: {q: qText, type: this.searchType},
	  success: (data) => {
		this.fetchRequestSuccess(data);
	  }
	});
  }
  
  renderItems(wrap, data, wrapEmpty = false, buttonsShow = ['btn-add-audio']){
	if (wrap !== null) {
	  if(wrapEmpty) {
		wrap.empty();
	  }
	  for (let i = 0, l = data.length; i < l; ++i) {
		let item = $(tmpl(templateItem(), {
		  id: data[i].id,
		  name: data[i].name,
		  duration: data[i].duration
		}));
		
		item.find('.btn-control').hide();
		for(let b = 0; b < buttonsShow.length; b++){
		  item.find('.'+ buttonsShow[b]).show();
		}
		
		wrap.append(item);
	  }
	}
	this.renderSelected();
  }
  
  renderSelected(){
    for(let i =0, l = this.items.length; i < l; i++){
      this.addedSelectedClass(this.items[i]);
	}
  }
  
  fetchRequestSuccess(data) {
	this.hdx = null;
	let wrap = null;
	
	if (this.searchType === SEARCH_TYPE.GLOBAL) {
	  wrap = this.getSelectorSearchItems();
	} else if (this.searchType === SEARCH_TYPE.INLIST) {
	  wrap = this.getSelectorUserItems();
	}
	this.renderItems(wrap, data, true);
  }
  
  
  onPressKeySearch(e) {
	if (e.which && e.which === 13) {
	  e.preventDefault();
	}
	
	let wrap = this.element;
	if (this.searchType === SEARCH_TYPE.GLOBAL) {
	  this.getSelectorSearchItems().show();
	  this.showBtnBack();
	}
	
	if (this.searchTimeOut) {
	  clearTimeout(this.searchTimeOut);
	  this.searchTimeOut = null;
	}
	
	this.searchTimeOut = setTimeout(
	  () => {
		this.fetchRequest();
	  },
	  this.options.searchDelay
	);
  }
  
  showBtnBack(){
	this.element.find('.btn-select-audios').hide();
	this.element.find('.btn-back-audios').show();
  }
  
  hideBtnBack(){
	this.element.find('.btn-select-audios').show();
	this.element.find('.btn-back-audios').hide();
  }
  
  onClickBackAudios(e) {
	e.preventDefault();
	this.searchType = SEARCH_TYPE.GLOBAL;
	
	this.setSearchValue('');
	this.getSelectorSearchItems().hide();
	this.getSelectorUserItems().hide();
	this.hideBtnBack();
  }
  
  onClickSelectAudios(e) {
	e.preventDefault();
	this.searchType = SEARCH_TYPE.INLIST;
 
	this.setSearchValue('');
	this.getSelectorSearchItems().hide();
	this.getSelectorUserItems().show();
	this.showBtnBack();
	this.fetchRequest();
  }
  
  onClickAddAudio(e) {
	e.preventDefault();
	
	let selectItems = this.getSelectorSelectItems();
	let item = $(e.currentTarget).closest('.apl-wrap-play-item');
	let dataId = item.attr('data-id');
	
	item = item.clone();
	item.find('.btn-add-audio').hide();
	item.find('.bnt-remove-audio').show();
	if (this.items.indexOf(Number(dataId)) === -1) {
	  this.addItemIndex(dataId);
	  this.addedSelectedClass(dataId);
	  selectItems.append(item);
	}
	this.savePlayListToInput();
  }
  
  addItemIndex(val){
	val = Number(val);
	const index = this.items.indexOf(val);
	if (index === -1) {
	  this.items.push(val);
	}
  }
  
  removeItemIndex(val){
	val = Number(val);
	const index = this.items.indexOf(val);
	if (index !== -1) {
	  this.items.splice(index, 1);
	}
  }
  
  findItemByIdInWrap(wrap, dataId){
    return wrap.find('.apl-wrap-play-item[data-id="' + dataId + '"]');
  }
  
  addedSelectedClass(dataId){
    this.findItemByIdInWrap(this.getSelectorSearchItems(), dataId).addClass('selected');
    this.findItemByIdInWrap(this.getSelectorUserItems(), dataId).addClass('selected');
  }
  
  removeSelectedClass(dataId){
	this.findItemByIdInWrap(this.getSelectorSearchItems(), dataId).removeClass('selected');
	this.findItemByIdInWrap(this.getSelectorUserItems(), dataId).removeClass('selected');
  }
  
  onClickRemoveAudio(e) {
    let item = $(e.currentTarget).closest('.apl-wrap-play-item');
	let dataId = item.attr('data-id')
	this.removeItemIndex(dataId);
	this.removeSelectedClass(dataId);
	item.remove();
	this.savePlayListToInput();
  }
  
  savePlayListToInput(){
    const items = this.items;
    let $input = this.element.closest('form').find('input[name="AudioPlayListForm[audios]"]');
	$input.val(items.join(','));
  }
  
  
  initEvents() {
	this.element.on('keydown', '.wdt-form-playlist-search-input', (e) => {
	  this.onPressKeySearch(e);
	});
	
	this.element.on('click', '.btn-select-audios', (e) => {
	  this.onClickSelectAudios(e);
	});
	
	this.element.on('click', '.btn-back-audios', (e) => {
	  this.onClickBackAudios(e);
	});
	
	this.element.on('click', '.btn-add-audio', (e) => {
	  this.onClickAddAudio(e);
	});
	
	this.element.on('click', '.bnt-remove-audio', (e) => {
	  this.onClickRemoveAudio(e);
	});
  }
  
}

module.exports = FormPlayListWidget;
