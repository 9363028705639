export default () => {
  return (`
  <div class="apl-wrap-play-item " data-id="{%= o.id %}">
	<div>
	  <div class="apl-play">
		  <a class="i-play" onclick="audioWrapPlay(this)"></a>
	  </div>
	  <div class="apl-body">
		<div class="apl-buttons">
		
			<a class="i-plus btn-add-audio btn-control" href="#"></a>
			
			<a class="bnt-remove-audio btn-control" href="#"><i class="fas fa-times"></i></a>
			
		</div>
		<div class="apl-duration">{%=o.duration %}</div>
		<div class="apl-name">
			<a href="#">{%=o.name %}</a>
		</div>
	  </div>
	</div>
	<div class="apl-wrap-progress">
	  <div class="bar"></div>
	</div>
  </div>
  `);
};
