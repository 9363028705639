export default () => {
  return (`
	<div class="">
	  <div class="wdt-form-playlist-search">
		<div type="wdt-form-playlist-icon"></div>
		<input type="text" class="{%= o.class_search_input %}" placeholder="Быстрый поиск">
	  </div>
	  <div class="wdt-form-playlist-controls">
		<div class="btn-select-audios">
			<a  href="#">Добавить аудиозаписи</a>
		</div>
		<div class="btn-back-audios">
			<a  href="#">Назад к аудиозаписям</a>
		</div>
	  </div>
	  <div class="wgt-layout-items">
		<div class="{%= o.class_search_items %}"></div>
		<div class="{%= o.class_select_user_items %}"></div>
		<div class="{%= o.class_select_items %}"></div>
	  </div>
	</div>
  `);
};
